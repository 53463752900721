import Logo from '../../Assets/Logo.svg'
import React from 'react'

const FooterCompany = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ fontWeight: '400', fontSize: '12px', color: '#333333' }}>Powered by</span>
            <span><img src={Logo} /></span>
        </div>
    )
}

export default FooterCompany
