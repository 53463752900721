import React from 'react'
import iPhone14 from '../../Assets/iPhone14.svg'
import mackbookProFrontView from '../../Assets/mackbookProFrontView.svg'

const DesktopPhoneWidget = () => {
    return (
        <div className="d-flex align-items-center justify-content-end" style={{
            width: "50%", height: '89.5vh'
        }}>
            <div className="d-flex align-items-center justify-content-end" style={{
                position: "relative", height: '95%', width: "90%"
            }}>
                <img src={mackbookProFrontView} style={{ height: "100%", width: "90%" }} />
                <img style={{ position: "absolute", top: '19%', left: '0%', height: "80%", objectFit: "contain" }} src={iPhone14} />
            </div>
        </div>
    )
}

export default DesktopPhoneWidget
